/* eslint-disable unicorn/numeric-separators-style */
const DEAL_ONE_ID = 31648647;
const DEAL_ONE_B_ID = 31926452;
const DEAL_TWO_ID = 31648658;
const DEAL_THREE_ID = 31762253;
const DEAL_FOUR_ID = 33201788;
const DEAL_FIVE_ID = 32372128;

const DEAL_ONE_HREF =
  'https://wowcher.creditec.co.uk/personal-loans/?utm_campaign=deal';
const DEAL_TWO_HREF =
  'https://ccc.creditec.co.uk/credit-cards/?source=wowcher&utm_campaign=deal ';
const DEAL_THREE_HREF =
  'https://ccc.creditec.co.uk/credit-cards/?source=wowcher&preferredchoice=22&utm_campaign=hsbcpc';
const DEAL_FOUR_HREF =
  'https://ccc.creditec.co.uk/credit-cards/?source=wowcher&preferredchoice=92&utm_campaign=zablepc';
const DEAL_FIVE_HREF =
  'https://ccc.creditec.co.uk/credit-cards/?source=wowcher&preferredchoice=23&utm_campaign=hsbcpc';

const creditecDealIds = [
  DEAL_ONE_ID,
  DEAL_TWO_ID,
  DEAL_ONE_B_ID,
  DEAL_THREE_ID,
  DEAL_FOUR_ID,
  DEAL_FIVE_ID,
];

export const isCreditecDeal = (deal) => {
  return creditecDealIds.includes(deal.id);
};

export const getCreditecHref = (deal) => {
  if (deal.id === DEAL_ONE_ID) return DEAL_ONE_HREF;
  if (deal.id === DEAL_ONE_B_ID) return DEAL_ONE_HREF;
  if (deal.id === DEAL_TWO_ID) return DEAL_TWO_HREF;
  if (deal.id === DEAL_THREE_ID) return DEAL_THREE_HREF;
  if (deal.id === DEAL_FOUR_ID) return DEAL_FOUR_HREF;
  if (deal.id === DEAL_FIVE_ID) return DEAL_FIVE_HREF;

  return '';
};
